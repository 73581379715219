/*
$gray-100: #f8f9fa;
$gray-600: #868e96;
$gray-800: #343a40;

$red: #dc3545;
$yellow: #ffc107;
$green: #006600; // Overwrite this color!
$cyan: #17a2b8;

$color-master:     #626262;
$color-primary:    #6D5CAE;
$color-complete:   #48B0F7;
$color-success:    #10CFBD;
$color-menu:       #202328;
$color-info:       #3B4752;
$color-danger:     #F55753;
$color-warning:    #F8D053;

$theme-colors: (
  primary: $green,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);
*/

$di-color-red: #ff4136;
$di-color-green: #7fff00;
