.dirt-pagination {
  color: #999;
  margin-top: 20px;

  > span {
    display: inline-block;
    min-width: 200px;
  }

  pagination-controls {
    display: inline-block;
  }

  ul.ngx-pagination {
    margin-bottom: 0;
    padding-left: 0;

    li {
      background: none;
      border: 1px solid transparent !important;
      border-radius: 3px !important;
      color: #666 !important;
      cursor: pointer;
      display: inline-block;
      position: relative;

      &.current {
        background-color: rgba(0, 0, 0, 0.05) !important;
        cursor: default;
      }

      &.disabled {
        color: #ddd !important;
        margin-right: 8px;
      }

      a {
        border: 1px solid transparent;
        border-radius: 3px !important;
        text-decoration: none;
        transition: all 0.2s ease;

        &:hover,
        &:focus {
          background-color: #fff !important;
          border-color: rgba(0, 0, 0, 0.1) !important;
        }
      }

      &.current,
      &.disabled,
      a {
        padding: 7px 10px !important;
      }

      &.pagination-previous,
      &.pagination-next {
        display: none;
      }
    }
  }
}
