$body-color-bg: #fafafa;

$tag-color-text: #fff;
$tag-color-bg: #cfae45;
$tag-color-text-hover: #fff;
$tag-color-bg-hover: #f8d053;

.ng2-tag-input {
  .ng2-tags-container {
    align-items: center;
  }

  .ng2-tag-input__text-input {
    background-color: transparent;
  }

  .progress-bar {
    bottom: -2px !important;
    left: 0;
  }

  tag-ripple,
  delete-icon {
    display: none !important;
  }
}

.ng2-tag-input.ng2-tag-input--loading {
  border-bottom: 2px solid transparent !important;
}

.ng2-tag-input > .ng2-tags-container tag {
  background: $tag-color-bg !important;
  border-radius: 3px;
  color: $tag-color-text;
  display: inline-block;
  font-size: 0.86em;
  height: 26px;
  line-height: 26px;
  padding: 0 12px 0 24px;
  margin-right: 10px;
  text-decoration: none;
  transition: color 0.2s;

  &::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
  }

  &::after {
    background: $body-color-bg;
    border-bottom: 13px solid transparent;
    border-right: 10px solid $tag-color-bg;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $tag-color-bg-hover !important;
    color: $tag-color-text-hover;

    &::after {
      border-right-color: $tag-color-bg-hover !important;
    }
  }
}
