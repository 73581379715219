html {
  font-size: 100%;
  text-size-adjust: 100%;
}

body {
  font-feature-settings: 'kern' 1;
  font-size: 14px;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
}

h1 {
  font-size: 2.2em;
}
h2 {
  font-size: 2em;
}
