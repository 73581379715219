@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import 'shared/animations';
@import 'shared/badges';
@import 'shared/buttons';
@import 'shared/datepicker';
@import 'shared/forms';
@import 'shared/nav';
@import 'shared/notifications';
@import 'shared/pagination';
@import 'shared/popover';
@import 'shared/rating';
@import 'shared/tables';
@import 'shared/tags';
@import 'shared/typography';
@import 'common';

/* * * * * * * * * * * * * * * * * * * * *
 * Elements
 */

html,
body {
  height: 100%;
}

body {
  background-color: #fafafa;
  color: #333;
  line-height: 1.4;
}

.di-backdrop-dark {
  opacity: 0.8 !important;
}
