ngb-rating {
  outline: none;

  > span + span {
    padding-left: 1px;
  }

  .star {
    color: #ddd;
    font-size: 1.1em;
  }

  .filled {
    color: #fde16d !important;
  }
}
