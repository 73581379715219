$color-white: #fff;
$color-black: #000;

$color-success: #10cfbd;
$color-complete: #48b0f7;
$color-primary: #6d5cae;
$color-secondary: #a5a6a4;
$color-warning: #f8d053;
$color-danger: #f55753;
$color-info: #3b4752;

$mix-percentage-light: 80%;

$color-success-light: mix($color-success, $color-white, $mix-percentage-light);
$color-complete-light: mix($color-complete, $color-white, $mix-percentage-light);
$color-primary-light: mix($color-primary, $color-white, $mix-percentage-light);
$color-warning-light: mix($color-warning, $color-white, $mix-percentage-light);
$color-danger-light: mix($color-danger, $color-white, $mix-percentage-light);
$color-info-light: mix($color-info, $color-white, $mix-percentage-light);

.state {
  &:before {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 10px;
    content: '';
    display: block;
    float: left;
    height: 8px;
    margin: 6px 0 0 0;
    width: 8px;
  }

  &.state-success:before {
    background-color: $color-success;
    border-color: $color-success-light;
  }

  &.state-complete:before {
    background-color: $color-complete-light;
    border-color: $color-complete;
  }

  &.state-primary:before {
    background-color: $color-primary-light;
    border-color: $color-primary;
  }

  &.state-warning:before {
    background-color: $color-warning-light;
    border-color: $color-warning;
  }

  &.state-danger:before {
    background-color: $color-danger-light;
    border-color: $color-danger;
  }

  &.state-info:before {
    background-color: $color-info-light;
    border-color: $color-info;
  }
}

.state {
  &.state-stacked {
    &:not(:first-child) {
      margin-left: -6px;
    }

    &:before {
      border-color: #fff !important;
      height: 11px;
      width: 11px;
    }
  }
}
