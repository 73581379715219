.btn {
  background: none;
  border: 1px solid transparent;
  border-radius: 0.3em;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  line-height: 2.3em;
  height: 2.4em;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0 1em;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-appearance: none;
}

.btn:hover,
.btn:focus,
.btn.focus {
  // color: #1385e5;
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
}

.btn[disabled],
.btn.disabled {
  cursor: not-allowed;
  opacity: 0.8;
  //pointer-events: none;
}

.btn-cons {
  min-width: 120px;
}

.btn-loading {
  min-width: 85px;

  &:before {
    animation: fa-spin 2s infinite linear;
    content: '\f021';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  > span {
    display: none;
  }
}

.btn-primary,
.btn-outline-primary {
  border-color: #1177cd #0f6ab6 #0d5c9e;
}

.btn-primary {
  background-image: linear-gradient(to bottom, #2591ed 0, #1177cd 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff2591ed', endColorstr='#ff1177cd', GradientType=0);
  background-color: #1385e5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus,
  &.focus {
    background-image: linear-gradient(to bottom, #3c9def 0, #1385e5 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c9def', endColorstr='#ff1385e5', GradientType=0);
    border-color: #1385e5 #1177cd #0f6ab6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: white;
    outline: none;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 3px rgba(19, 133, 229, 0.25);
  }

  &:active,
  &.active {
    background-color: #117ad2;
    background-image: none;
    border-color: #0d5c9e #0f6ab6 #1177cd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}

.btn-secondary {
  background-image: linear-gradient(to bottom, #fafafa 0%, #eaeaea 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffeaeaea', GradientType=0);
  border-color: #ccc #bdbdbd #adadad;
  color: #333;
  font-weight: 400;
  text-shadow: 0 1px 0 white;

  &:hover,
  &:focus,
  &.focus {
    background-image: linear-gradient(to bottom, #fff 0, #eee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
    border-color: #bfbfbf #bfbfbf #b3b3b3;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: #333;
  }

  &:focus,
  &.focus {
    border-color: #1385e5;
    box-shadow: 0 0 0 3px rgba(19, 133, 229, 0.1);
    outline: none;
  }

  &:active,
  &.active {
    background-color: #e6e6e6;
    border-color: #b3b3b3;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #333;
  }

  &:active:focus,
  &:active.focus,
  &.active:focus,
  &.active.focus {
    outline: none;
  }

  &.disabled,
  &[disabled] {
    background-color: #f0f0f0;
    box-shadow: none;
  }
}

.btn-success,
.btn-outline-success {
  border-color: #2fae39 #299a33 #24862c;
}

.btn-success {
  background-image: linear-gradient(to bottom, #43cd4f 0, #2fae39 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff43cd4f', endColorstr='#ff2fae39', GradientType=0);
  background-color: #34c240;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus,
  &.focus {
    background-image: linear-gradient(to bottom, #57d261 0, #34c240 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff57d261', endColorstr='#ff34c240', GradientType=0);
    border-color: #34c240 #2fae39 #299a33;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: white;
    outline: none;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 3px rgba(52, 194, 64, 0.25);
  }

  &:active,
  &.active {
    background-color: #30b23b;
    background-image: none;
    border-color: #24862c #299a33 #2fae39;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}

.btn-warning,
.btn-outline-warning {
  border-color: #f9922e #f98515 #ee7806;
}

.btn-warning {
  background-image: linear-gradient(to bottom, #fbac60 0, #f9922e 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbac60', endColorstr='#fff9922e', GradientType=0);
  background-color: #fa9f47;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus,
  &.focus {
    background-image: -webkit-linear-gradient(top, #fbb979 0, #fa9f47 100%);
    background-image: -o-linear-gradient(top, #fbb979 0, #fa9f47 100%);
    background-image: linear-gradient(to bottom, #fbb979 0, #fa9f47 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb979', endColorstr='#fffa9f47', GradientType=0);
    border-color: #fa9f47 #f9922e #f98515;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: white;
    outline: none;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 3px rgba(250, 159, 71, 0.25);
  }

  &:active,
  &.active {
    background-color: #f99533;
    background-image: none;
    border-color: #ee7806 #f98515 #f9922e;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}

.btn-danger,
.btn-outline-danger {
  border-color: #d12d2d #bc2929 #a72424;
}

.btn-danger {
  background-image: linear-gradient(to bottom, #db5757 0, #d12d2d 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdb5757', endColorstr='#ffd12d2d', GradientType=0);
  background-color: #d64242;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 400;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus,
  &.focus {
    background-image: linear-gradient(to bottom, #df6c6c 0, #d64242 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdf6c6c', endColorstr='#ffd64242', GradientType=0);
    border-color: #d64242 #d12d2d #bc2929;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: white;
    outline: none;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 3px rgba(214, 66, 66, 0.25);
  }

  &:active,
  &.active {
    background-color: #d23131;
    background-image: none;
    border-color: #a72424 #bc2929 #d12d2d;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}
