@keyframes bounceInRight {
  0%,
  100%,
  60%,
  75%,
  90% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    transform: translate3d(500px, 0, 0);
    opacity: 0;
  }
  60% {
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes scaleFadeIn {
  from {
    opacity: 0;
    transform: scale3d(0.95, 0.95, 1);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
