/* * * * * * * * * * * * * * * * * * * * *
 * Common
 */

.dirt-cloak {
  background-color: #fff;
  height: 100%;

  > div {
    animation: 800ms linear infinite heartbeat;
    background: no-repeat url('../assets/veeva.png') center center;
    background-size: 80px;
    height: 100%;
    transform-origin: 50% 50%;
  }
}

.dirt-btn-back {
  float: left;
  margin: 15px 20px 25px 0;
}

.dirt-loading {
  text-align: center;
  padding: 200px;
}

a.di-timid {
  color: #333;
}

/* * * * * * * * * * * * * * * * * * * * *
 * Common: Section
 */

.dirt-section-list,
.dirt-section-detail,
.dirt-section-create {
  //animation: scaleFadeIn ease-in .25s;
  //animation-fill-mode: forwards;
  //opacity: 0;

  h1 {
    padding-top: 1rem;
  }
}

/* * * * * * * * * * * * * * * * * * * * *
 * Common: Section List
 */

.dirt-section-list {
  > header {
    > div {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }

    > div:nth-child(2) {
      text-align: center;
    }

    h1 {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  table {
    margin: 10px 0;

    thead th {
      border-top: none;
    }
  }

  .dirt-no-results {
    font-weight: 400;
    text-align: center;
    margin-top: 50px;
  }
}

/* * * * * * * * * * * * * * * * * * * * *
 * Common: Section Detail & Create
 */

.dirt-section-create,
.dirt-section-detail {
  margin-bottom: 50px;

  .dirt-btn-ct {
    margin-top: -2px;
    margin-left: 16px;
  }

  .dirt-title-ct {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 25px;
  }
}

.dirt-section-detail {
  .dirt-section-list {
    @include clearfix;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-top: 50px;
    padding: 30px 50px;
  }

  .dirt-section-meta {
    &.tab-content,
    .tab-content {
      background-color: #fff;
      border: 1px solid #ddd;
      border-top-width: 0;
      padding: 15px;
    }

    dl {
      margin-bottom: 0;
      padding: 20px;

      .di-separator {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 1rem;
        padding-top: 1.3rem;
      }
    }

    dt {
      font-weight: normal;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * *
 * Animations
 */

.bounceInRight {
  animation-name: bounceInRight;
  animation-duration: 0.4s;
}

/* * * * * * * * * * * * * * * * * * * * *
 * Rating
 */

.rating {
  .star-icon {
    font-size: 1.1em !important;
  }
}

/* * * * * * * * * * * * * * * * * * * * *
 * Fieldset
 */

$color-warning: #f8d053;

.dirt-fieldset {
  padding: 20px;
  margin-bottom: 0;
  background-color: #d6eaf8;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/**
 * Show hand on Cursor
 */
.dirt-link-pointer {
  cursor: pointer;
}

dirt-multiselect-dropdown .dropdown-menu {
  z-index: 99999;
}

.form-focus {
  border-color: #90ee90;
  outline: 0;
}

dirt-multiselect-dropdown {
  .dropdown-toggle {
    height: 38px;
  }

  &[required=''] {
    &.ng-invalid {
      .dropdown-toggle {
        border-left: 5px solid #a94442;
      }
    }

    &.ng-valid {
      .dropdown-toggle {
        border-left: 5px solid #42a948;
      }
    }
  }
}

dirt-project-name {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: break-spaces !important;
    word-wrap: break-word;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    white-space: break-spaces !important;
    word-wrap: break-word;
  }
}
