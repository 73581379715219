.dirt-navbar-sub {
  .navbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb;
    margin: -15px -15px 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .navbar-nav {
    color: #666;
    margin-left: 168px;

    &:hover {
      color: rgba(102, 102, 102, 0.66);
    }

    li > a {
      font-weight: normal;
      padding: 0.75em 1em !important;
      position: relative;
      transition: color 180ms;

      &:hover,
      &.is-active {
        color: #333;
      }

      &.is-active {
        &:after,
        &:before {
          border: 8px solid transparent;
          border-bottom-width: 0;
          content: '';
          height: 0;
          left: 50%;
          margin-left: -8px;
          position: absolute;
          width: 0;
        }

        &:before {
          border-top-color: #ccc;
          bottom: -9px;
        }

        &:after {
          border-top-color: #f5f5f5;
          bottom: -8px;
        }
      }
    }
  }
}
