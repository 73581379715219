.table th.di-col-sorter {
  cursor: pointer;

  &::after {
    content: '\f0dc';
    font: normal normal normal 14px/1 FontAwesome;
    margin-left: 5px;
    opacity: 0.2;
  }

  &.sort-asc::after,
  &.sort-desc::after {
    opacity: 0.8;
  }

  &.sort-asc::after {
    content: '\f0de';
  }

  &.sort-desc::after {
    content: '\f0dd';
  }
}
