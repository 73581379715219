form {
  .ng-valid[required]:not(.no-validate) {
    border-left: 5px solid #42a948;
  }

  .ng-valid[allowEmpty]:not(.no-validate) {
    border-left: 5px solid #42a948;
  }

  .ng-invalid:not(.no-validate) {
    border-left: 5px solid #a94442;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.form-control {
  border-radius: 0.3em;
  border: 1px solid #ccc;
  border-top-color: #c2c2c2;
  border-bottom-color: #d6d6d6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
  // -webkit-appearance: none;
  // -moz-appearance: none;

  &:hover {
    border-color: #b3b3b3;
    outline: 0;
  }

  &:focus,
  &:focus {
    border-color: #1385e5;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(19, 133, 229, 0.1);
    outline: 0;
  }

  &[disabled],
  &.disabled {
    background-color: #f7f7f7;
    pointer-events: none;
  }

  &::placeholder {
    opacity: 0.5;
  }

  &::-moz-focus-inner {
    border: 0;
    outline: 0;
  }
}

dirt-multiselect-dropdown {
  ::ng-deep .dropdown-menu {
    ::ng-deep .dropdown-item {
      span[role='menuitem'] {
        // Icons wrapper
        > span:first-child {
          .fa-lock {
            display: none;
          }
        }

        // Text
        > span.disabled:last-child {
          font-style: italic;
          opacity: 0.5;
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;

    ::ng-deep .dropdown-toggle {
      opacity: 0.7;

      &::after {
        display: none;
      }
    }
  }

  &.ng-valid,
  &.ng-invalid {
    border-left: none !important;
  }

  &.ng-invalid ::ng-deep .dropdown-toggle {
    border-left: 5px solid #a94442;
  }
}
